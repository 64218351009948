import React from "react"
import { Helmet } from "react-helmet"
import SubpageSheet from "../../views/SubpageSheet/SubpageSheet"
import Footer from "../../views/Footer"
import Navigation from "../../components/Navigation/Navigation"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const Flights = () => {
  const { formatMessage, locale } = useIntl()
  const data = useStaticQuery(graphql`
    {
      cloud: file(name: { eq: "cloud-small" }) {
        childImageSharp {
          fluid(maxWidth: 428, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cloudMedium: file(name: { eq: "cloud-medium" }) {
        childImageSharp {
          fluid(maxWidth: 674, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hero: file(name: { eq: "flights-hero" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      flights1: file(name: { eq: "flights1" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const description = () => {
    return (
      <FormattedMessage
        id="Flights description1"
        values={{
          strong: chunks => <strong>{chunks}</strong>,
        }}
      />
    )
  }
  const description2 = () => {
    return (
      <FormattedMessage
        id="Flights description2"
        values={{
          strong: chunks => <strong>{chunks}</strong>,
          br: <br />,
          b: chunks => <b>{chunks}</b>,
        }}
      />
    )
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <meta charSet="utf-8" />
        <title data-react-helmet="true">
          {formatMessage({ id: "Touristic flights" })} | Skywalkers Balloon Club
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
        />
        <meta name="description" data-react-helmet="true" content="" />
        <meta
          data-react-helmet="true"
          property="og:title"
          content={`${formatMessage({
            id: "Touristic flights",
          })} | Skywalkers Balloon Club`}
        />
        <meta data-react-helmet="true" property="og:description" content="" />
        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/skywalkers-1362f.appspot.com/o/homepage.jpg?alt=media&token=bde9c4f3-3638-4f5a-8e32-6b10a1bcd834"
        />
      </Helmet>
      <Navigation />

      <SubpageSheet
        title={`${formatMessage({
          id: "Touristic flights",
        })}`}
        subtitle={`${formatMessage({
          id: "A great adventure and unforgettable experience",
        })}`}
        heroImage={data.hero.childImageSharp.fluid}
        cloudMedium={data.cloudMedium.childImageSharp.fluid}
        cloudSmall={data.cloud.childImageSharp.fluid}
        anotherImage={data.flights1.childImageSharp.fluid}
        description={description}
        description2={description2}
        page="flights"
      />

      <Footer />
    </>
  )
}

export default Flights
